select {
  -webkit-appearance: none;
}

.menuOpen {
  animation: menuOpen 0.2s forwards;
}

@keyframes menuOpen {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.menuClose {
  animation: menuClose 0.2s forwards;
}

@keyframes menuClose {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}